export default {
  init() {
    // JavaScript to be fired on all pages
    
    // initiate home slider
      $('.slider').slick({
        autoplay: true,
        pauseOnHover: true,
        arrows: false,
        dots: false,
        infinite: true,
        speed: 2500,
        fade: true,
        cssEase: 'linear',
      });


      $('.automatic_slider').slick({
        speed: 1500,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        swipeToSlide: true,
        centerMode: true,
        focusOnSelect: true,
        arrows: false,
        dots: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll:1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });

        $('.gerelateerde_item_slider').slick({
          autoplay: true,
          pauseOnHover: true,
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  
     // header change on scroll
     $(window).scroll(function(){
      var sticky = $('#header'),
          scroll = $(window).scrollTop();
    
      if (scroll >= 100){
        sticky.addClass('fixed');
      }else{
        sticky.removeClass('fixed');
      }
    });

    // hamburger menu
    // menu toggle mobile 
    $('.icon_burger').on( 'click', function() {
      $('.top-menu').toggleClass('top-animate');
      $('.mid-menu').toggleClass('mid-animate');
      $('.bottom-menu').toggleClass('bottom-animate');

      $('.nav-primary').toggleClass('active-menu');
      
      $('body').toggleClass('noscroll');
    });

    $('.menu-item-has-children .svg-inline--fa').on( 'click', function() {
      
      $( this ).parent().find( '.sub-menu' ).toggleClass( 'active-sub-menu' );

    });


  },
};